import React from "react";
import "../styles/footer.css"



const Footer = () => {


    return (
        <div className="footer-container">
            <div className="header-logo"></div>
            <div className="nav-bar-container"></div>
        </div>
    )
}

export default Footer